import "../styles/404-style.css";

export default function Page404() {
    return (
        <div>
            <head>
                <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/css/bootstrap.min.css' />
            </head>
            <body>
                <section class="page_404">
                    <div>
                        <div class="text-center">
                            <div class="four_zero_four_bg">
                                <h1 class="text-center">404</h1>
                            </div>
                            <div class="contant_box_404">
                                <h3 class="h2">
                                    ¡Parece que estás perdido!
                                </h3>
                                <p>La página que estás buscando no existe.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </body>
        </div>
    );
}