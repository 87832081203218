import React, { useEffect, useState } from "react";
import {
    Alert,
    AppBar,
    Backdrop,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Snackbar,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import NumberFormat from 'react-number-format';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from "@mui/styles";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import LogoCNC from "../images/logo-cnc.png";
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';

import 'date-fns';
//import esLocale from 'date-fns'
import MomentUtils from '@date-io/moment';
import "moment/locale/es";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import Page404 from "./Page404";
import Copyright from "../Copyright";

const API_DEFAULT = "https://back-desarrollos-service-q2nhgfzuoq-uc.a.run.app";


const useStyles = makeStyles((theme) => ({
    logo: {
        width: 110,
        [theme.breakpoints.up("sm")]: {
            width: 135
        }
    },
    logoSpacing: {
        marginRight: theme.spacing(1.5),
        [theme.breakpoints.up("sm")]: {
            marginRight: theme.spacing(2)
        },
        display: "flex"
    },
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px !important",
            [theme.breakpoints.up("sm")]: {
                fontSize: "16px !important"
            }
        }
    },
    customFontTitle: {
        fontSize: "18px !important",
        [theme.breakpoints.up("sm")]: {
            fontSize: "22px !important"
        }
    },
    customFontBody: {
        fontSize: "14px !important",
        [theme.breakpoints.up("sm")]: {
            fontSize: "16px !important"
        }
    },
    customFontAlert: {
        fontSize: "13px !important",
        [theme.breakpoints.up("sm")]: {
            fontSize: "14px !important"
        }
    },
    displayDesktop: {
        display: "none !important",
        [theme.breakpoints.up("sm")]: {
            display: "flex !important"
        }
    },
    displayMobile: {
        display: "flex !important",
        [theme.breakpoints.up("sm")]: {
            display: "none !important"
        }
    }
}));

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Child({ id, appBar, classes, setMessage, setSeverity, setSnack, setShowBackdrop, uuidValido, formularioDiligenciado, fetchUUID }) {
    const [nombre, setNombre] = useState("");
    const [genero, setGenero] = useState("");
    const [estrato, setEstrato] = useState("Elegir");
    const [ciudad, setCiudad] = useState("Elegir");
    const [edad, setEdad] = useState("Elegir");
    const [fecha, setFecha] = useState(null);
    const [hora, setHora] = useState("Elegir");

    const [errores, setErrores] = useState(Array(8).fill(false));
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    var moment = require('moment')
    moment.locale('es');

    const GENEROS = [
        {id: 0, genero:'Masculino'},
        {id: 1, genero:'Femenino'}
    ]
    
    const CIUDADES = [
        {id: 0, nombre: 'Armenia'},
        {id: 1, nombre: 'Barranquilla'},
        {id: 2, nombre: 'Bogotá'},
        {id: 3, nombre: 'Bucaramanga'},
        {id: 4, nombre: 'Cali'},
        {id: 5, nombre: 'Cartagena'},
        {id: 6, nombre: 'Cúcuta'},
        {id: 7, nombre: 'Ibagué'},
        {id: 8, nombre: 'Manizales'},
        {id: 9, nombre: 'Medellín'},
        {id: 10, nombre: 'Montería'},
        {id: 11, nombre: 'Neiva'},
        {id: 12, nombre: 'Pasto'},
        {id: 13, nombre: 'Pereira'},
        {id: 14, nombre: 'Popayán'},
        {id: 15, nombre: 'Santa Marta'},
        {id: 16, nombre: 'Tunja'},
        {id: 17, nombre: 'Villavicencio'}
    ]

    const MIN_EDAD = 12;
    const MAX_EDAD = 69;
        
    const HORAS_AGENDA = [
        { id: 0, rango: '8:00 a.m. a 9:00 a.m.'},
        { id: 1, rango: '9:00 a.m. a 10:00 a.m.'},
        { id: 2, rango: '10:00 a.m. a 11:00 a.m.'},
        { id: 3, rango: '11:00 a.m. a 12:00 p.m.'},
        { id: 4, rango: '12:00 p.m. a 1:00 p.m.'},
        { id: 5, rango: '1:00 p.m. a 2:00 p.m.'},
        { id: 6, rango: '2:00 p.m. a 3:00 p.m.'},
        { id: 7, rango: '3:00 p.m. a 4:00 p.m.'},
        { id: 8, rango: '4:00 p.m. a 5:00 p.m.'},
        { id: 9, rango: '5:00  p.m. a 6:00 p.m.'},
        { id: 10, rango: '6:00 p.m. a 7:00 p.m.'},
        { id: 11, rango: '7:00 p.m. a 8:00 p.m.'}
    ]

    const borrarFormulario = () => {
        setNombre("");
        setGenero("");
        setEstrato("Elegir");
        setCiudad("Elegir");
        setEdad("Elegir");
        setFecha(null);
        setHora("Elegir");

        setErrores(Array(8).fill(false));
    }


    const postDatos = async function () {
        setShowBackdrop(true);
        let date = new Date(fecha);
        date = date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + date.getDate();
        const res = await fetch(API_DEFAULT + '/ecar-agendamiento/datos-formulario', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'nombre': nombre,
                'genero': GENEROS.filter(g => g.genero === genero)[0].id,
                'estrato': estrato,
                'ciudad': CIUDADES.filter(c => c.nombre === ciudad)[0].id, 
                'edad': edad,
                'fecha_agenda': date,
                'hora_agenda': HORAS_AGENDA.filter(h => h.rango === hora)[0].id,
                'uuid': id
            })
        })
        

        if (res.ok) {
            fetchUUID();
            setShowBackdrop(false);
        } else {
            
            await res.json().then(async res => {
                setShowBackdrop(false);
                setMessage('No se guardaron los datos correctamente.');
                setSeverity('warning');
                setTimeout(() => { setSnack(true) }, 0);
            });
        }
    }

    const validarDatos = () => {
        let errorDatos = false;

        if (nombre === "") {
            errorDatos = true;
            errores[0] = true;
        }
        if (genero === "") {
            errorDatos = true;
            errores[1] = true;
        }
        if (estrato === "Elegir" || estrato === "") {
            errorDatos = true;
            errores[2] = true;
        }
        if (ciudad === "Elegir" || ciudad === "") {
            errorDatos = true;
            errores[3] = true;
        }
        if (edad === "Elegir" || edad === "") {
            errorDatos = true;
            errores[4] = true;
        }
        if (fecha === null) {
            errorDatos = true;
            errores[5] = true;
        }
        if (hora === "Elegir" || hora === "") {
            errorDatos = true;
            errores[6] = true;
        }
        if (edad < MIN_EDAD || edad > MAX_EDAD){
            errorDatos = true;
            errores[8] = true;
        }

        if (errorDatos) {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            postDatos();
        }
    }

    const formulario = function () {

        return (
            <React.Fragment>
                {appBar}
                <Container maxWidth="md">
                    <Card style={{ marginBottom: 12, borderRadius: 8 }} variant="outlined">
                        <CardContent style={{ padding: 24 }}>
                            <Typography className={classes.customFontTitle} style={{ fontWeight: 500, marginBottom: 16 }}>Medios y Radios de Colombia</Typography>
                            <Typography className={classes.customFontBody} align="justify" style={{ marginBottom: 12, lineHeight: 1.3 }}>El Centro Nacional de Consultoría es una empresa dedicada a la investigación de mercados. Estamos desarrollando un estudio sobre <span style={{ fontWeight: 500 }}>CONSUMO DE MEDIOS DE COMUNICACIÓN</span>. <span style={{ fontWeight: 500 }}>ACLARAMOS QUE NO REPRESENTAMOS A NINGÚN MEDIO DE COMUNICACIÓN</span>. Su identidad así como las respuestas suministradas serán de carácter confidencial y los datos se utilizarán únicamente para fines estadísticos.</Typography>
                            <Typography className={classes.customFontBody} align="justify" style={{ marginBottom: 12, lineHeight: 1.3 }}>Al completar sus datos podrá participar en una entrevista telefónica de 10 a 15 minutos. Por el tiempo dedicado en la encuesta telefónica, usted obtendrá un bono de un helado en <span style={{ fontWeight: 500 }}>Helados Gourmet Popsy</span>. Se lo haremos llegar vía mensaje de texto o WhatsApp.</Typography>
                            <Typography className={classes.customFontBody} align="justify" style={{ marginBottom: 12, lineHeight: 1.3 }}>Para cualquier inquietud puede comunicarse con nosotros al teléfono: 601 339 4888.</Typography>
                            <Typography className={classes.customFontBody} style={{ color: "#D93025" }}>*Obligatorio</Typography>
                        </CardContent>
                    </Card>
                    <Card style={{ marginBottom: 12, borderRadius: 8 }} variant="outlined">
                        <CardContent style={{ padding: 24 }}>
                            <Typography className={classes.customFontBody} style={{ marginBottom: 12 }}>Nombre <span style={{ color: "#D93025" }}>*</span></Typography>
                            <TextField
                                classes={{ root: classes.customTextField }}
                                inputProps={{ className: classes.customFontBody }}
                                onChange={(event) => {
                                    setNombre(event.target.value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1 ]/g, ''));
                                    errores[0] = false;
                                    setErrores([...errores]);
                                }}
                                value={nombre}
                                size="small"
                                variant="standard"
                                placeholder="Tu respuesta"
                                fullWidth
                                error={errores[0]}
                                helperText={errores[0] ? 'Este campo no puede quedar vacío' : null}
                            />
                        </CardContent>
                    </Card>
                    <Card style={{ marginBottom: 12, borderRadius: 8 }} variant="outlined">
                        <CardContent style={{ padding: 24 }}>
                            <Typography className={classes.customFontBody} style={{ marginBottom: 12 }}>Género <span style={{ color: "#D93025" }}>*</span></Typography>
                            <RadioGroup
                                value={genero}
                                onChange={(event) => { setGenero(event.target.value); errores[1] = false; setErrores([...errores]) }}
                            >
                                <FormControlLabel value="Masculino" control={<Radio />} label={<Typography className={classes.customFontBody}>Masculino</Typography>} />
                                <FormControlLabel value="Femenino" control={<Radio />} label={<Typography className={classes.customFontBody}>Femenino</Typography>} />
                            </RadioGroup>
                            <span style={{ display: errores[1] ? null : "none", fontSize: 12, color: "#E53935" }}>Debe selecionar alguna opción</span>
                        </CardContent>
                    </Card>
                    <Card style={{ marginBottom: 12, borderRadius: 8 }} variant="outlined">
                        <CardContent style={{ padding: 24 }}>
                            <Typography className={classes.customFontBody} style={{ marginBottom: 12 }}>Estrato <span style={{ color: "#D93025" }}>*</span></Typography>
                            <Select
                                classes={{ select: classes.customFontBody }}
                                value={estrato}
                                size="small"
                                fullWidth
                                onChange={(event) => { setEstrato(event.target.value); errores[2] = false; setErrores([...errores]) }}
                                renderValue={(selected) => {
                                    if (selected === "Elegir") {
                                        return <span style={{ color: "rgba(0, 0, 0, 0.35)" }}>Elegir</span>
                                    }


                                    return selected;
                                }}
                                error={errores[2]}
                            >
                                <MenuItem className={classes.customFontBody} disabled value="">Elegir</MenuItem>
                                {[...Array(6).keys()].map(i => (
                                    <MenuItem className={classes.customFontBody} key={i + 1} value={i + 1}>
                                        {i + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                            <span style={{ display: errores[2] ? null : "none", fontSize: 12, color: "#E53935" }}>Debe elegir alguna opción</span>
                        </CardContent>
                    </Card>
                    <Card style={{ marginBottom: 12, borderRadius: 8 }} variant="outlined">
                        <CardContent style={{ padding: 24 }}>
                            <Typography className={classes.customFontBody} style={{ marginBottom: 12 }}>Ciudad <span style={{ color: "#D93025" }}>*</span></Typography>
                            <Select classes={{ select: classes.customFontBody }} value={ciudad} size="small" fullWidth
                                onChange={(event) => { setCiudad(event.target.value); errores[3] = false; setErrores([...errores]) }}
                                renderValue={(selected) => {
                                    if (selected === "Elegir") {
                                        return <span style={{ color: "rgba(0, 0, 0, 0.35)" }}>Elegir</span>
                                    }

                                    return selected;
                                }}
                                error={errores[3]}
                            >
                                <MenuItem className={classes.customFontBody} disabled value="">
                                    Elegir
                                </MenuItem>
                                {CIUDADES.map(ciudad => (
                                    <MenuItem className={classes.customFontBody} key={ciudad.id} value={ciudad.nombre}>{ciudad.nombre}</MenuItem>
                                ))}
                            </Select>
                            <span style={{ display: errores[3] ? null : "none", fontSize: 12, color: "#E53935" }}>Debe elegir alguna opción</span>
                        </CardContent>
                    </Card>
                    <Card style={{ marginBottom: 12, borderRadius: 8 }} variant="outlined">
                        <CardContent style={{ padding: 24 }}>
                            <Typography className={classes.customFontBody} style={{ marginBottom: 12 }}>Edad <span style={{ color: "#D93025" }}>*</span></Typography>
                            <NumberFormat 
                                customInput={TextField}
                                classes={{ root: classes.customTextField }}
                                inputProps={{ className: classes.customFontBody }}
                                value={edad}
                                onChange={(event) => { setEdad(event.target.value); errores[4] = false; errores[8] = false; setErrores([...errores]) }}
                                format={'##'}
                                variant="standard"
                                placeholder="Tu respuesta"
                                fullWidth
                                isAllowed={values => {
                                    const {formattedValue, floatValue} = values
                                    return formattedValue === '' || (floatValue >= 1 && floatValue <= MAX_EDAD)
                                }}
                                error={errores[4] || errores[8]}
                                helperText={errores[4] ? 'Este campo no puede quedar vacío' : (errores[8]?'Debe elegir una edad entre '+MIN_EDAD+' y '+MAX_EDAD+' años':null)}
                            />
                        </CardContent>
                    </Card>
                    <Card style={{ marginBottom: 12, borderRadius: 8 }} variant="outlined">
                        <CardContent style={{ padding: 24 }}>
                            <Typography className={classes.customFontBody} style={{ marginBottom: 12 }}>Por favor, indique la fecha en la que desea agendar la encuesta <span style={{ color: "#D93025" }}>*</span></Typography>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                                <KeyboardDatePicker
                                    classes={{ root: classes.customTextField }}
                                    inputProps={{ className: classes.customFontBody }}
                                    disableToolbar
                                    format="dddd, D [de] MMMM [de] yyyy"
                                    size="small"
                                    fullWidth={true}
                                    value={fecha}
                                    onChange={(value) => { setFecha(value); errores[5] = false; setErrores([...errores]) }}
                                    minDate={new Date().setDate((new Date()).getDate() + 1)}
                                    maxDate={new Date().setDate(new Date().getDate() + 7)}
                                    placeholder='Elegir'
                                    onClick={() => setDatePickerOpen(true)}
                                    open={datePickerOpen}
                                    onClose={() => setDatePickerOpen(false)}
                                    InputProps={{ readOnly: true }}
                                    error={errores[5]}
                                    cancelLabel="Cancelar"
                                    clearLabel="Borrar"
                                    clearable={true}
                                    autoOk={true}
                                />
                            </MuiPickersUtilsProvider>
                            <span style={{ display: errores[5] ? null : "none", fontSize: 12, color: "#E53935" }}>Debe elegir alguna opción</span>
                        </CardContent>
                    </Card>
                    <Card style={{ marginBottom: 12, borderRadius: 8 }} variant="outlined">
                        <CardContent style={{ padding: 24 }}>
                            <Typography className={classes.customFontBody} style={{ marginBottom: 12 }}>¿A qué hora quiere que lo contactemos? <span style={{ color: "#D93025" }}>*</span></Typography>
                            <Select classes={{ select: classes.customFontBody }} value={hora} size="small" fullWidth
                                onChange={(event) => { setHora(event.target.value); errores[6] = false; setErrores([...errores]) }}
                                renderValue={(selected) => {
                                    if (selected === "Elegir") {
                                        return <span style={{ color: "rgba(0, 0, 0, 0.35)" }}>Elegir</span>
                                    }

                                    return selected;
                                }}
                                error={errores[6]}
                            >
                                <MenuItem className={classes.customFontBody} disabled value="">
                                    Elegir
                                </MenuItem>
                                {HORAS_AGENDA.map(hora => (
                                    <MenuItem className={classes.customFontBody} key={hora.id} value={hora.rango}>{hora.rango}</MenuItem>
                                ))}
                            </Select>
                            <span style={{ display: errores[6] ? null : "none", fontSize: 12, color: "#E53935" }}>Debe elegir alguna opción</span>
                        </CardContent>
                    </Card>
                    <Stack className={classes.displayDesktop} direction="row" spacing={2} justifyContent="flex-end">
                        <Button variant="outlined" startIcon={<DeleteIcon />} onClick={borrarFormulario}>Borrar formulario</Button>
                        <Button variant="contained" endIcon={<SendIcon />} disableElevation onClick={validarDatos}>Enviar</Button>
                    </Stack>
                    <Stack className={classes.displayMobile} direction="row" spacing={1.5} justifyContent="flex-end">
                        <Button size="small" variant="outlined" startIcon={<DeleteIcon />} onClick={borrarFormulario}>Borrar formulario</Button>
                        <Button size="small" variant="contained" endIcon={<SendIcon />} disableElevation onClick={validarDatos}>Enviar</Button>
                    </Stack>
                </Container>
            </React.Fragment>
        );
    }

    const finFormulario = function () {
        return (<React.Fragment>
            {appBar}
            <Container maxWidth="md">
                <Card style={{ marginBottom: 12, borderRadius: 8 }} variant="outlined">
                    <CardContent style={{ padding: 24 }}>
                        <Typography className={classes.customFontTitle} style={{ fontWeight: 500, marginBottom: 16 }}>Medios y Radios de Colombia</Typography>
                        <Typography className={classes.customFontBody} align="justify" style={{ marginBottom: 12, lineHeight: 1.3 }}>Los datos han sido diligenciados, muchas gracias por su participación.</Typography>
                    </CardContent>
                </Card>
            </Container>
        </React.Fragment>
        );
    }

    const error404 = function () {
        return (
            <Redirect to="/ecar/404" />
        );
    }

    const renderizar = function () {
        if (uuidValido !== null && !formularioDiligenciado) {
            return formulario();
        } else if (uuidValido !== null && formularioDiligenciado) {
            return finFormulario();
        } else {
            return error404();
        }
    }


    return renderizar();

}

export default function Main(props) {
    const { theme } = props;
    const classes = useStyles();
    const query = useQuery();

    const [showBackdrop, setShowBackdrop] = useState(false);
    const [snack, setSnack] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');

    const [uuidValido, setUuidValido] = useState(null);
    const [formularioDiligenciado, setFormularioDiligenciado] = useState(null);
    const [uuidOk, setUuidOk] = useState(false);

    const fetchUUID = async function () {
        setShowBackdrop(true);
        const res = await fetch(API_DEFAULT + '/ecar-agendamiento/datos-uuid/' + query.get("id"), {
            headers: { 'Content-Type': 'application/json' }
        })
        if (res.ok) {
            await res.json().then(res => {
                setUuidValido(res.uuid);
                setFormularioDiligenciado(res.formulario_diligenciado);
                send_visita();
            });

        }
        setTimeout(() => {
            setUuidOk(true);
            setShowBackdrop(false);
            
        }, 0);

    }

    const send_visita = async function(){
        setShowBackdrop(true);
        const res = await fetch(API_DEFAULT + '/ecar-agendamiento/datos-visitas/' + query.get("id"), {
            headers: { 'Content-Type': 'application/json' }
        })
        if (res.ok) {
            await res.json().then(res => {
                setShowBackdrop(false);
            });
        }
    }

    useEffect(() => {
        fetchUUID();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const appBar = (
        <AppBar position="static" style={{ marginBottom: theme.spacing(2) }}>
            <Toolbar>
                <div className={classes.logoSpacing}>
                    <a style={{ display: "contents" }} href={"https://www.centronacionaldeconsultoria.com/"}>
                        <img src={LogoCNC} alt="logo-cnc" className={classes.logo} />
                    </a>
                </div>
                <Typography variant="h5" style={{ flexGrow: 1, fontWeight: 500 }}>
                    Formulario ECAR
                </Typography>
            </Toolbar>
        </AppBar>
    );

    return (
        <React.Fragment>
            {
                uuidOk ?
                    <Switch>
                        <Route exact path="/ecar/form">
                            <Child id={query.get("id")} appBar={appBar} classes={classes} setMessage={setMessage} setSeverity={setSeverity} setSnack={setSnack} setShowBackdrop={setShowBackdrop} uuidValido={uuidValido} formularioDiligenciado={formularioDiligenciado} fetchUUID={fetchUUID} />
                            <Copyright />
                            <Backdrop style={{ zIndex: 1301 }} className={classes.backdrop} open={showBackdrop}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            <Snackbar
                                open={snack}
                                autoHideDuration={6000}
                                onClose={() => setSnack(false)}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            >
                                <Alert className={classes.customFontAlert} onClose={() => setSnack(false)} severity={severity} variant="filled">
                                    {message}
                                </Alert>
                            </Snackbar>
                        </Route>
                        <Route exact path="/ecar/404">
                            <Page404 />
                        </Route>
                        <Route path="*">
                            <Redirect to="/ecar/404" />
                        </Route>
                    </Switch>
                    :
                    null
            }
        </React.Fragment>
    );
}