import { Box, Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    customFont: {
        fontSize: "13px !important",
        [theme.breakpoints.up("sm")]: {
            fontSize: "14px !important"
        }
    }
}));

export default function Copyright() {
    const classes = useStyles();

    return (
        <Box mt={8} mb={3}>
            <Typography className={classes.customFont} variant="body2" align="center" sx={{ color: "#616161" }}>
                {"Copyright © "}
                <Link style={{ fontSize: "inherit" }} variant="body2" color="inherit" underline="hover" href="https://www.centronacionaldeconsultoria.com/">
                    Centro Nacional de Consultoría (CNC)
                </Link>{" "}
                {new Date().getFullYear()}
            </Typography>
        </Box>
    );
}